import React from "react";
import loadable from '@loadable/component'

const FeaturedLogos = loadable(() =>  import("./featured-logos"));
// const FeaturedMasonryIcons = loadable(() =>  import("./featured-masonry-icons"));

const Start = ({ module = { display: '' } }) => {
    const { iconListing = {} } = module;
    const { display } = iconListing;

    switch(display) {
        case "featured-logos":
            return <FeaturedLogos module={iconListing} />;
        // case "featured-masonry-icons":
        //     return <FeaturedMasonryIcons module={iconListing} />;
        default:
            return null;
    }
};

export default Start;